<template>
    <div class="col-12 col-lg-11 col-xl-10 px-0 mt-5 game-load-block no-gutters gameContainer" ref="gameContainer">

        <div class="row mx-0 pt-5">

            <div class="col-12 col-md-9 mx-auto px-0 mt-2 mt-lg-5 game-cross-block">
                <div class="row w-100" v-if="!loaded">
                    <Alert :message="response" class="game-load-alert"></Alert>
                </div>

                <div class="px-0 game-response-container text-center" id="game-response-container">
                    <div class="row mx-0 mb-2 buttons-container">
                        <a href="#" class="btn btn-primary enableFullScreen  orange-color d-md-inline-block"
                           id="fullScreenBtn"
                           @click.prevent="screenToggle">
                            <i class="fa fa-arrows-alt" v-if="!isFullScreenMode"></i>
                            <i class="fa fa-close" v-else></i>
                        </a>
                        <a :href="gameCloseUrl" class="btn close-btn text-white mx-3">
                            <i class="fa fa-close"></i>
                            Закрыть игру
                        </a>
                    </div>
                    <iframe class="gameLaunchIframe" :src="pregameLaunchUrl()" frameborder="0"
                            allowfullscreen style="max-width:100%">
                    </iframe>
                </div>
            </div>

            <div class="col-3 pt-5 d-none d-xl-block mt-5" id="gameInfoArea"
                 v-if="!isGameLive() && typeof this.provider !== 'undefined' && provider.toLowerCase() !== 'wheeloffortune'">
                <b-tabs class="game-page-tab game-info-area py-3 px-1">
                    <b-tab class="col-12">

                        <template slot="title" class="mt-2 mx-0">
                            <div class="tab-title pl-0 pr-1 col-12 text-center category-item">
                                <img :src="winnersIcon" class="tab-icon d-block mx-auto mb-4" alt="winners">
                                <button class="btn white-color game-tab-btn px-1 py-2"> Победители
                                </button>
                            </div>
                        </template>
                        <div class="winners-tab-body w-100">
                            <div class="row mx-0 border-0 px-4 py-2 tab-title-area">
                                <div class="col-2 px-0"><span class="winners-title my-3 font-weight-bold"> # </span>
                                </div>
                                <div class="col-6 px-0"><span
                                        class="winners-title my-3 font-weight-bold"> Ник игрока </span></div>
                                <div class="col-4 px-0"><span
                                        class="winners-title my-3 font-weight-bold"> Выигрыш </span>
                                </div>
                            </div>
                        </div>

                        <div class="winners">
                            <div class="row mx-0 winner py-3 px-2 text-center" v-for="(winner, index) in winners"
                                 v-if="winners"
                                 :key="'winner_'+index"
                                 v-bind:class="[index % 2 ? 'light-bg' : '']">

                                <div class="col-2 px-0">
                                    <span class="gray-color font-weight-bold">{{index + 1}}</span>
                                </div>

                                <div class="col-6 px-0">
                                    <div class="row mx-0">
                                        <span class="gray-color font-weight-bold">{{winner.login}}</span>
                                    </div>
                                </div>

                                <div class="col-4 px-0">
                                    <span class="gray-color font-weight-bold">{{winner.amount}} Руб</span>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab class="col-12" active>
                        <template slot="title" class="mt-2 mx-0">
                            <div class="tab-title pr-0 pl-1 col-12 text-center category-item">
                                <img :src="gamesIcon" class="tab-icon d-block mx-auto mb-4" alt="games">
                                <button class="btn white-color game-tab-btn py-2"> Игры</button>
                            </div>
                        </template>
                        <div class="game-tab-body w-100">
                            <div class="row mx-0 border-0 px-4 tab-title-area">
                                <span class="game-rateing-title my-3 font-weight-bold"> Рейтинг популярных игр: </span>
                            </div>

                            <div class="games">
                                <div class="row mx-0 game py-3 px-2" v-for="(game, index) in games"
                                     :key="'game_'+index"
                                     @click.prevent.stop="gotoGameView(game)"
                                     v-bind:class="[index % 2 ? 'light-bg' : '']">
                                    <div class="col-2 index-area">
                                        <span class="list-index gray-color font-weight-bold">{{index + 1}}</span>
                                    </div>
                                    <div class="col-10 h-100">
                                        <div class="row h-100">
                                            <div class="col-6 h-100">
                                                <img :src="getImageUrl(game)" class="game-img mh-100" :alt="game.name">
                                            </div>
                                            <div class="col-6">
                                                <span class="orange-color game-title">{{game.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <div>
            <SuperomaticSelecter
                    v-on:superoNominationSelected="superoNominationSelected"
                    :game="game"
            ></SuperomaticSelecter>
        </div>
        <div v-if="isGameLive">
            <LiveGameTableSelector
                    v-on:lobbyDataSelected="lobbyDataSelected"
                    :game="game"
                    :restoring="restoring"
                    :isLive="isLive"
                    :provider="provider"
            ></LiveGameTableSelector>
        </div>
    </div>
</template>

<script>
    /*eslint no-debugger: "error"*/

    import {scroller} from 'vue-scrollto/src/scrollTo'
    import Alert from '@/components/Alert'
    import Config from '@/config'
    import SuperomaticSelecter from "./SuperomaticSelecter";
    import LiveGameTableSelector from "./LiveGameTableSelector";

    const secondScrollTo = scroller()

    export default {
        name: 'TheGame',
        props: ['game', 'provider', 'gameName', 'gameId', 'restoring', 'isLive'],
        data() {
            return {
                isLandscape: false,
                response: {status: 'loading'},
                winnersIcon: require('@/../public/images/icons/games/winners.png'),
                gamesIcon: require('@/../public/images/icons/games/game.png'),
                isFullScreenMode: false,
                superomaticNomination: null,
                liveGameLobby: null,
                loaded: false,
                logoPreview:false,
                gameLaunchMobileResponse: null
            }
        },
        components: {
            Alert,
            SuperomaticSelecter,
            LiveGameTableSelector
        },
        watch: {
            isLandscape() {
                this.isLandscape = screen.height < screen.width
            },
            restoring() {
                if (this.restoring) {
                    this.$root.$emit('bv::hide::modal', 'HasAliveGame')
                }
            }
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            isMobile() {
               return this.$_config.environment.mobile
            }, 
            games() {
                let allGames = this.$store.getters['games']
                let keys = Object.keys(allGames)
                let rndKey = keys.length * Math.random() << 0
                let topGames = []

                if (typeof allGames[keys[rndKey]] !== 'undefined') {
                    topGames = allGames[keys[rndKey]].games.slice(0, 10)
                }
                return topGames
            },
            winners() {
                return this.$store.getters['getGameWinner']
            },
            gameCloseUrl() {
                return Config.baseUrl + '/Api/CloseGame/' + this.provider
            }
        },
        methods: {
            isGameLive() {
                return this.isLive === 1 ? true : false
            },

            pregameLaunchUrl() {
                if (!this.isMobile) {
                return this.gameLaunchUrl()
                } else {
                if (!this.provider || this.provider.toLowerCase() !== 'wheeloffortune') {
                    this.gameLaunchMobile()
                    if (this.gameLaunchMobileResponse) {
                    return this.gameLaunchUrl()
                    }
                } else {
                    return this.gameLaunchUrl()
                }
                }
            },
            gameLaunchUrl() {
                if (!this.provider) {
                    return ''
                }
                if (this.isGameLive() && !this.restoring && typeof this.provider !== 'undefined' && this.provider.toLowerCase() === "slotegrator") {
                    if (!this.liveGameLobby && !this.restoring) {
                        this.$root.$emit('bv::show::modal', 'liveTablesModal')
                        return ''
                    } else {
                        return [
                            Config.baseUrl,
                            '/Api/LoadGame/',
                            this.provider,
                            '/',
                            this.gameId ? this.gameId : '',
                            "?gamelaunchData%5BlobbyData%5D=" + this.liveGameLobby
                        ].join('')
                    }
                }

                let gameLaunchUrl = [
                    Config.baseUrl, '/Api/LoadGame/',
                    this.provider, '/',
                    this.gameId ? this.gameId : ''
                ].join('')

                if (typeof this.provider !== 'undefined' && this.provider.toLowerCase() !== 'superomatic') {
                    return gameLaunchUrl
                } else {
                    if (!this.superomaticNomination && !this.restoring) {
                        this.$bvModal.show('superomaticModal')
                        return ''
                    } else {
                        gameLaunchUrl += "?gamelaunchData%5Bnomination%5D=" + this.superomaticNomination
                        return gameLaunchUrl
                    }
                }

            },
            gameLaunchMobile() {
                this.axios.get(
                    this.gameLaunchUrl()
                ).then(({ data }) => {
                    if (data instanceof Object) {
                    if (data.status) {
                        if (data.status === "ok") {
                            window.location.href = data.data.url
                            setTimeout(() => {
                                this.$router.push({name: 'home'})
                            }, 3000)
                        }
                    } else {
                        this.gameLaunchMobileResponse = data
                    }
                    } else {
                      this.gameLaunchMobileResponse = data
                    }
                });
                },
            superoNominationSelected(value) {
                this.superomaticNomination = value
                this.loaded = true
            },
            lobbyDataSelected(value) {
                this.liveGameLobby = value
                this.loaded = false
                if (value) {
                    this.loaded = true
                }
            },
            getImageUrl(game) {
                return game.picture;
            },
            containerSize() {
                try {
                    let availWidth = document.querySelector('.game-cross-block').offsetWidth
                    let koef = 1.8
                    let availHeight = availWidth / koef
                    let sizes = {
                        width: availWidth,
                        height: availHeight
                    }
                    return sizes
                } catch (err) {
                    console.log(err.message)
                }
            },
            screenToggle() {
                if (document.fullscreenElement) {
                    this.closeFullscreen();
                } else {
                    this.fullscreen()
                }
            },
            fullscreen() {
                let framesDiv = document.querySelector("#game-response-container");
                if (document.fullscreenEnabled ||
                    document.webkitFullscreenEnabled ||
                    document.mozFullScreenEnabled ||
                    document.msFullscreenEnabled) {
                    if (framesDiv.requestFullscreen) {
                        framesDiv.requestFullscreen();
                    } else if (framesDiv.webkitRequestFullscreen) {
                        framesDiv.webkitRequestFullscreen();
                    } else if (framesDiv.mozRequestFullScreen) {
                        framesDiv.mozRequestFullScreen();
                    } else if (framesDiv.msRequestFullscreen) {
                        framesDiv.msRequestFullscreen();
                    }
                    if (framesDiv.webkitExitFullscreen) {
                        framesDiv.webkitExitFullscreen()
                    }
                }
            },
            closeFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            },
            gotoGameView(game) {
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        gameName: game.name,
                        gameId: game.id,
                        game: game,
                        provider: game.system,
                        isLive: game.isLive
                    }
                })
            },
            setIframeSizes() {
                let container = document.querySelector('.game-response-container')
                if (!container) {
                    return
                }
                let sizes = {
                    width: container.offsetWidth,
                    height: container.offsetWidth / 1.9
                }
                document.querySelector('.gameLaunchIframe').style.height = sizes.height + 'px'
                document.querySelector('.gameLaunchIframe').style.maxHeight = sizes.height + 'px'
                document.querySelector('.gameLaunchIframe').style.width = sizes.width + 'px'
                document.querySelector('.gameLaunchIframe').style.maxWidth = sizes.width + 'px'
                if (!this.isGameLive() && typeof this.provider !== 'undefined' && this.provider.toLowerCase() !== 'wheeloffortune') {
                    document.querySelector('.game-info-area').style.height = sizes.height - 10 + 'px'
                }
            },
            setOriantationOnChangeEvent() {
                window.addEventListener('resize', () => {
                    setTimeout(() => {
                        this.isLandscape = window.innerWidth > window.innerHeight
                        this.setIframeSizes()
                    }, 20)
                })
            }
        },
        mounted() {
            if (!this.$store.getters['aliveGames']) {
                return this.$router.push({name: "home"})
            }
            this.setOriantationOnChangeEvent();
            this.setIframeSizes()

            if (document.querySelector('.gameLaunchIframe') !== null) {
                document.querySelector('.gameLaunchIframe').onload = () => {
                    this.loaded = true
                }
            }

            if (!this.userData) {
                return this.$router.push({name: 'login'})
            }

            let gamesLoadParams = {
                provider: this.provider,
                gameId: this.gameId
            }
            if(typeof gamesLoadParams.provider !== 'undefined' && typeof gamesLoadParams.gameId !== "undefined") {
              this.$store.dispatch('fetchGameWinners', gamesLoadParams)
            }

            setTimeout(() => {
                secondScrollTo('iframe')
            }, 600)

            let fullScreenBtn = document.querySelector('#fullScreenBtn')

            document.addEventListener('webkitfullscreenchange', this.fullscreenChange)
            document.addEventListener('mozfullscreenchange', this.fullscreenChange)
            document.addEventListener('fullscreenchange', this.fullscreenChange)
            document.addEventListener('MSFullscreenChange', this.fullscreenChange)
            document.addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement) {
                    fullScreenBtn.innerHTML = '<i class="fa fa-window-minimize"></i>'
                } else {
                    fullScreenBtn.innerHTML = '<i class="fa fa-arrows-alt"></i>'
                }
            })
            document.getElementById('game-response-container').addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement) {
                    fullScreenBtn.innerHTML = '<i class="fa fa-window-minimize"></i>'
                } else {
                    let frame = document.querySelector('#game-response-container');

                    frame.style.height = this.containerSize().height + 'px'
                    fullScreenBtn.innerHTML = '<i class="fa fa-arrows-alt"></i>'
                }
            });
        }
    }
</script>

<style>
    .winner,
    .game-title {
        font-size: 14px;
    }

    .gameLaunchIframe {
        height: 100%;
    }

    .enableFullScreen {
        background-color: transparent !important;
        border: none !important;
    }

    .buttons-container {
        justify-content: flex-end;
        height: 35px;
    }

    .game-page-tab .tab-pane {
        padding: 0 !important;
    }

    .game-page-tab .nav-tabs {
        border: none !important;
        justify-content: center !important;
    }

    .game-page-tab .nav-link.active {
        background-color: transparent !important;
        border: none !important;
    }

    .game-page-tab .nav-link {
        border: none !important;
        padding: 0 !important;
    }

    .game-page-tab .nav-item {
        width: 50%;
    }

    .game-page-tab .nav-link.active .game-tab-btn {
        background: linear-gradient(0deg, rgba(254, 138, 17, 1) 3%, rgb(218, 159, 11) 52%, rgb(211, 182, 9) 96%) !important;
    }

    .main-container {
        height: calc(100% - 65px) !important;
    }
</style>

<style scoped>
    .gameLaunchIframe {
        height: 100%;
        width: 100%;
        max-width: 100% !important;
        max-height: 100% !important;
    }

    .close-btn {
        background: linear-gradient(0deg, rgba(254, 138, 17, 1) 3%, rgb(218, 159, 11) 52%, rgb(211, 182, 9) 96%) !important;
        border: none;
        border-radius: 16px;
    }

    .gameContainer {
        height: 100%;
    }

    .tab-icon {
        height: 35px;
        width: auto;
    }

    .tab-title-area {
        border-top: 1px solid #10131c;
        border-bottom: 1px solid #10131c;
        color: #565759;
    }


    .game-info-area .tab-content {
        overflow-y: scroll;
        height: 100%;
        max-height: 100%;
    }

    .game-info-area::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }

    .game-info-area::-webkit-scrollbar-thumb {
        background-color: gray;
        border-radius: 7px;
    }

    .light-bg {
        background-color: #10131c;
    }

    .game {
        cursor: pointer;
        height: 100px;
    }

    .list-index {
        display: inline-block;
        vertical-align: middle;
    }

    .game-img {
        border-radius: 7px;
        height: auto;
        max-height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .game-rateing-title {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 600;
    }

    .winners-title {
        font-size: 12px;
    }

    .game-tab-btn:hover {
        box-shadow: 0 0 10px rgba(207, 178, 32, 0.6) !important;
    }

    .game-info-area {
        background-color: rgba(11, 14, 21, 0.6);
        overflow-x: hidden;
    }

    .game-tab-btn {
        width: 100%;
        background: #1b2026 !important;
        border: none !important;
        border-radius: 20px;
    }

    .game-load-block {
        width: 100%;
        min-height: fit-content !important;
        background-repeat: no-repeat;
        height: fit-content;
        margin: 0 auto !important;
    }


    .game-load-block .game-load-alert {
        position: absolute;
        z-index: 2;
        top: 150px;
        left: 50%;
        width: 70%;
        margin-left: -35%;
        text-align: center;
        min-height: 200px;
    }

    .game-load-block .game-load-alert .alert {
        min-height: 200px;
        align-items: center !important;
        justify-content: center;
        display: flex !important;
        padding: 0 !important;
    }

    #app.mobile .page-content.main-block {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .resp-loader.loading {
        max-width: 300px;
        max-height: 300px;
        top: unset;
        left: unset;
    }

    .landscape-close-btn {
        top: 10px;
    }
</style>


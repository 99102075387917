<template>
    <b-modal id="HasAliveGame" title="У вас есть активная игра" size="md"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             :hide-header-close="true"
    >
        <div class="form-group" v-if="hasAliveGame">
            <div class="row text-center">
                <img :src="$config.storageUrl + '/' + hasAliveGame.game.preview.replace(/^\/|\/$/g, '')" class="mx-auto alive-popup-game-image" alt="Alive Game">
            </div>
            <div class="row justify-content-between mt-2">
                <div class="col-7 text-center px-0 mx-auto">
                    <a class="btn btn-orange d-block" @click.prevent="restore"> Продолжить </a>
                </div>
                <div class="col-6 text-center px-0 mx-auto">
                    <a class="btn btn-gray white-color mt-3" :href="closeUrl"> Закрыть </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import Config from "@/config"

    export default {
        name: "AliveGamePopup",
        computed: {
            hasAliveGame() {
                let aliveGames = this.$store.getters['aliveGames']
                if (aliveGames) {
                    return aliveGames[0]
                }
                return false
            },
            closeUrl() {
                if (this.hasAliveGame) {
                    return Config.baseUrl + '/Api/closeGame/' + this.hasAliveGame.game.type
                }
                return ''
            },
        },
        methods: {
            restore() {
                this.$bvModal.hide('HasAliveGame')
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        gameName: this.hasAliveGame.game.game_name,
                        gameId: this.hasAliveGame.game.game,
                        game: this.hasAliveGame.game,
                        provider: this.hasAliveGame.game.type,
                        restoring: true
                    }
                })
            }
        },
        watch: {
            hasAliveGame() {
                if (this.hasAliveGame) {
                    this.$bvModal.show('HasAliveGame')
                }
            }
        },
        mounted() {
            if (this.hasAliveGame) {
                this.$bvModal.show('HasAliveGame')
            }
        }
    }
</script>

<style scoped>
    .alive-popup-game-image {
        width: 200px;
    }
</style>
